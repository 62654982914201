<template>
  <section>
    <div class="searchwrap">
      <div class="searchBar">
         <div class="box-ui-select searchbar-content">
           <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
           <div>
            <input type="text" class="mr-5" v-model="reqData.memId" :placeholder="'ID'" />
            <button class="btn-search" type="button" @click="pageSearch">
              <i class="fas fa-search"></i>
            </button>
           </div>
         </div>

         <div class="box-ui-toggle searchbar-content">
           <div class="title"><span>{{ $t('searchArea.condition') }}</span></div>
           <div class="toggle-container">
             <div class="toggle-item">
               <span class="toggle-title">{{ $t('searchArea.includeBlack') }}</span>
               <input type="checkbox" class="set-switch" v-model="reqData.memBlackYn" />
             </div>
           </div>
         </div>
      </div>
    </div>

    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <thead>
            <tr>
              <th rowspan="2">{{ $t('table.head.id') }}</th>
              <th rowspan="2">{{ $t('table.head.balance_2') }}</th>
              <th rowspan="2">{{ $t('table.head.outWait') }}</th>
              <th rowspan="2">{{ $t('common.point') }}</th>
              <th colspan="3">{{ $t('totalInfo.progBetAmt') }}</th>
              <th rowspan="2">{{ $t('common.total') }}</th>
            </tr>
            <tr>
              <!-- <th>스포츠</th>
              <th>미니게임</th>
              <th>E-스포츠</th>
              <th>키론</th> -->
              <th>{{ $t('table.head.casino') }}</th>
              <th>{{ $t('table.head.slot') }}</th>
              <!-- <th>피싱</th> -->
              <th>{{ $t('table.head.allBetAmt') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="tableData.length !== 0">
              <tr v-for="item in tableData" :key="item.memId">
                <td>
                  <button type="button" class="fc-id btn-link" @click="detailOpen('user', item.memId)">{{ item.memId }}</button>
                </td>
                <td>{{ numberWithCommas(item.cashAmt) }}</td>
                <td>{{ numberWithCommas(item.cashOutWait) }}</td>
                <td>{{ numberWithCommas(item.pointAmt) }}</td>

                <!-- <td>{{ item.sportAmt }}</td>
                <td>{{ item.minigameAmt }}</td>
                <td>{{ item.esportAmt }}</td>
                <td>{{ item.kironAmt }}</td> -->
                <td>{{ numberWithCommas(item.casinoAmt) }}</td>
                <td>{{ numberWithCommas(item.slotAmt) }}</td>
                <!-- <td>{{ item.fishingAmt }}</td> -->
                <td>{{ numberWithCommas(item.totalAmt) }}</td>
                <td>{{ numberWithCommas(item.total) }}</td>
              </tr>
            </template>
          </tbody>
          <tbody class="family-wrap total">
            <td>{{ $t('searchArea.total') }}</td>
            <td>{{ totalData.cashAmt }}</td>
            <td>{{ totalData.cashOutWait }}</td>
            <td>{{ totalData.pointAmt }}</td>
            <!-- <td>{{ totalData.sportAmt }}</td>
            <td>{{ totalData.minigameAmt }}</td>
            <td>{{ totalData.esportAmt }}</td>
            <td>{{ totalData.kironAmt }}</td> -->
            <td>{{ totalData.casinoAmt }}</td>
            <td>{{ totalData.slotAmt }}</td>
            <!-- <td>{{ totalData.fishingAmt }}</td> -->
            <td>{{ totalData.totalAmt }}</td>
            <td>{{ totalData.total }}</td>
          </tbody>
          <!-- <template v-else>
            <td colspan="11">{{ $t('txt.noData') }}</td>
          </template> -->
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import lodash from "lodash";
import SearchFilter from "@/components/common/SearchFilter";
import DateSelector from "@/components/common/DateSelector";
import TableHead from "@/components/main/table/Head.vue";
import UiCheck from "@/components/ui/UiCheckSet";
import subTitle from "@/components/main/PageSubTitle";
import { reportCompany, getCode } from "@/api/member.js";
import { GAME_INFO_LIST, GAME_CODE_NAME } from "@/libs/constants";
import { replaceDateT, numberWithCommas, getDateStr } from "@/libs/utils.js";
import Pagination from "@/components/common/Pagination";

export default {
  name: "BetList",
  components: {
    SearchFilter,
    DateSelector,
    TableHead,
    UiCheck,
    subTitle,
    Pagination
  },
  data: function () {
    return {
      tableData: {},
      headInfo: {
        fstColumn: false,
        dataList: ["index", "memId", "betType", "betCnt", "betAmt", "specialBetAmt", "wl", "contractorTotal"]
      },
      reqData: {
        memId: "",
        memBlackYn: "N"
      },
      tableData: [],
      pageInfo: {},
      betList: [],
      codeList: [],
      convertData: {},
      gameType: {},
      tabList: {},
      currentPage: "",
      gameList: [],
      gameAllYn: false,
      totalData: {
        cashAmt: 0,
        cashOutWait: 0,
        pointAmt: 0,
        sportAmt: 0,
        minigameAmt: 0,
        esportAmt: 0,
        kironAmt: 0,
        casinoAmt: 0,
        slotAmt: 0,
        fishingAmt: 0,
        totalAmt: 0,
        total:0
      }
    };
  },
  methods: {
    pageSearch(){
      this.setTableData();
    },
    async getBetList(code, pageNum) {
      if (pageNum) {
        this.reqData.page = pageNum;
      }
      this.reqData.gameType = code;
      const data = this.reqData;
      return await betList(data);
    },
    async getGameCode(masterCode) {
      return await getCode(masterCode);
    },
    async setGameList() {
      const gameList = await this.getGameCode("kplay");
      console.log(gameList);
      if (gameList.length !== 0) {
        for (const item of gameList) {
          const groupCode = item.groupCode;
          const gameCode = item.code;
          const gTxtCode = GAME_INFO_LIST[groupCode][gameCode.toString()];
          if (gTxtCode) {
            const gameTextKey = this.$t(`gameList.${gTxtCode}`);
            const rateObj = {
              gameTxtKey: gameTextKey,
              gameType: gameCode,
              yn: false
            };
            this.gameList.push(rateObj);
          }
        }
      }
    },
    goSearch() {
      this.setTableData();
    },
    async setTableData() {
      const req = lodash.cloneDeep(this.reqData);
      for(const key in req){
        if(typeof req[key] === "boolean"){
          if(req[key]){
            req[key]=""
          }else{
            req[key]="N"
          }
        }
      }
      const res = await reportCompany(req);
      if (res.resultCode === "0") {
        const dataList = res.data.list;
        for (const item of dataList) {
          item.totalAmt = 0;
          item.total = 0;
          for (const child of item.betList) {
            switch (child.groupCode) {
              case "casino":
                item.casinoAmt = child.betAmt;
                break;
              case "esport":
                item.esportAmt = child.betAmt;
                break;
              case "kiron":
                item.kironAmt = child.betAmt;
                break;
              case "minigame":
                item.minigameAmt = child.betAmt;
                break;
              case "fishing":
                item.fishingAmt = child.betAmt;
                break;
              case "slot":
                item.slotAmt = child.betAmt;
                break;
              case "sport":
                item.sportAmt = child.betAmt;
                break;
            }
            item.totalAmt += Number(child.betAmt);
          }
          const total = 0;
          for (const key in item) {
            if (key !== "betList" && key !== "memId") {
              // console.log(item[key]);
              total += Number(item[key]);
            }
          }
          item.total = total;
        }
        this.tableData = dataList;
      }
      let _total = 0;
      for (const key in this.totalData) {
        if (key !== "betList" && key !== "memId") {
          // console.log(this.totalData[key]);
          _total += Number(this.totalData[key]);
        }
      }
      this.totalData.total = _total;
    }
  },
  async created() {
    this.setGameList();
    this.setTableData();
  }
};
</script>

<style scoped>
.main-sub-data-item {
  flex: 1 1 0;
  margin-right: 10px;
  border: 1px solid #c7c7c7;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 3px 3px 12px 0px #d1d1d1;
  display: flex;
}
.main-sub-data-item > div {
  padding: 8px 0;
  text-align: center;
  box-sizing: border-box;
}
.sub-data-item-title {
  background: #ab2926;
  color: #fff;
  font-weight: 700;
  width: 35%;
}
.sub-data-item-content {
  font-weight: 700;
  text-align: right;
  width: 65%;
}
.main-sub-data-item:last-child {
  margin-right: 0;
}
.page-contents-wrap {
  margin-bottom: 10px;
  display: flex;
}
.page-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.btn-total-data {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  background: #2f4858;
  width: fit-content;
  border-radius: 3px;
  border: 2px solid #2f4858;
}
.btn-total-data:hover {
  color: #2f4858;
  background: #f1f1e6;
}
.btn-total-data > i {
  margin-right: 5px;
}
.btn-total-data > span {
  font-weight: 700;
  letter-spacing: 2px;
}
</style>
